import type { AnalyticsAccountScopeUserSettings } from 'owa-analytics-types';
import type WebSessionType from './contract/WebSessionType';

let userType: string | null = null;

export default function getUserType(
    accountSettings?: AnalyticsAccountScopeUserSettings,
    isEdu?: boolean | undefined
): string {
    if (!userType) {
        if (accountSettings?.SessionSettings?.WebSessionType != undefined) {
            if (accountSettings.SessionSettings.WebSessionType.toString() !== (0).toString()) {
                if (accountSettings.IsConsumerChild) {
                    userType = 'c_child';
                } else if (accountSettings.SessionSettings.IsPremiumConsumerMailbox) {
                    userType = 'c_premium';
                    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                     * Adding IsShadowMailbox to restricted properties/methods.
                     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
                } else if (accountSettings.SessionSettings.IsShadowMailbox) {
                    userType = 'c_shadow';
                } else {
                    userType = 'c_standard';
                }
            } else {
                userType = isEdu ? 'b_edu' : 'b_standard';
            }
        } else {
            userType = 'unknown';
        }
    }

    return userType;
}

export function test_resetUserType() {
    userType = null;
}
