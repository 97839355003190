import { getThreadName, type ThreadName } from 'owa-thread-config';

// Get the database name to use when caching telemetry events while offline.
export function getOfflineTelemetryDatabasePrefix(tenantToken: string) {
    // When we reconnect, each OfflineChannel will try to send the events from its db.
    // So we use thread name as part of the db name to avoid sending the same event from each multiple threads.
    const threadName: ThreadName = getThreadName() || 'MAIN_THREAD';

    // We use the tenant token as part of the db name to avoid mixing events from different tenants.
    return `owa-ai-${threadName}-${tenantToken}`;
}
