import { logOneDSDatapoint } from '../OneDsWrapper';
import type { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-js';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'getVariantEnvironment' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { getVariantEnvironment } from 'owa-metatags';

export async function logAddinsCustomerContent(tenantToken: string, item: IExtendedTelemetryItem) {
    const endpointUrl = getCustomerContentCollectorUrl();

    if (!endpointUrl) {
        // We only log Customer Content telemetry event for 'Prod'
        // and 'Dogfood'. For other environments, we drop it.
        return;
    }
    // Returning is important so the errors can be handled by the main thread.
    return logOneDSDatapoint({
        tenantToken,
        item,
        overrideEndpointUrl: endpointUrl,
    });
}

function getCustomerContentCollectorUrl(): string | undefined {
    const environment = getVariantEnvironment();

    switch (environment) {
        case 'Prod':
        case 'Dogfood':
            return 'https://office-c.events.data.microsoft.com/OneCollector/1.0/';
        default:
            return undefined;
    }
}
