import type { AnalyticsUserConfiguration } from 'owa-analytics-types';
import type { ReadOnlyOwaUserConfiguration } from 'owa-service/lib/ReadOnlyTypes';

export function cherryPickUserConfiguration(
    userConfiguration: ReadOnlyOwaUserConfiguration
): AnalyticsUserConfiguration {
    const {
        UserOptions,
        SessionSettings,
        ViewStateConfiguration,
        AttachmentPolicy,
        MailboxCreateDate,
    } = userConfiguration;

    return {
        SessionSettings: {
            TenantDomain: SessionSettings?.TenantDomain,
            DefaultFolderIds: SessionSettings?.DefaultFolderIds?.length,
            DefaultFolderNames: SessionSettings?.DefaultFolderNames?.length,
            UserPuid: SessionSettings?.UserPuid,
            UserDisplayName: SessionSettings?.UserDisplayName,
            UserPrincipalName: SessionSettings?.UserPrincipalName,
            UserEmailAddress: SessionSettings?.UserEmailAddress,
            WebSessionType: SessionSettings?.WebSessionType?.toString(),
            ExternalDirectoryTenantGuid: SessionSettings?.ExternalDirectoryTenantGuid,
            IsEdu: SessionSettings?.IsEdu,
        },
        MailboxCreateDate,
        UserOptions: {
            GlobalReadingPanePositionReact: UserOptions?.GlobalReadingPanePositionReact,
            GlobalListViewTypeReact: UserOptions?.GlobalListViewTypeReact?.toString(),
            ShowPreviewTextInListView: UserOptions?.ShowPreviewTextInListView,
            ShowSenderOnTopInListView: UserOptions?.ShowSenderOnTopInListView,
            ShowInlinePreviews: UserOptions?.ShowInlinePreviews,
            NewEnabledPonts: UserOptions?.NewEnabledPonts?.toString(),
            ThemeStorageId: UserOptions?.ThemeStorageId?.toString(),
            IsDarkModeTheme: UserOptions?.IsDarkModeTheme,
            DisplayDensityMode: UserOptions?.DisplayDensityMode?.toString(),
            ConsumerAdsExperimentMode: UserOptions?.ConsumerAdsExperimentMode?.toString(),
            KeyboardShortcutsMode: UserOptions?.KeyboardShortcutsMode?.toString(),
            ShowReadingPaneOnFirstLoad: UserOptions?.ShowReadingPaneOnFirstLoad,
            FirstOWALogon: UserOptions?.FirstOWALogon,
            FirstOWAReactMailLogon: UserOptions?.FirstOWAReactMailLogon,
            FirstOWAReactPeopleLogon: UserOptions?.FirstOWAReactPeopleLogon,
            FirstOWAReactCalendarLogon: UserOptions?.FirstOWAReactCalendarLogon,
            FirstOWAReactMiniLogon: UserOptions?.FirstOWAReactMiniLogon,
            FirstMonarchLogon: UserOptions?.FirstMonarchLogon,
        },
        ViewStateConfiguration: {
            FocusedInboxBitFlags: ViewStateConfiguration?.FocusedInboxBitFlags,
            GlobalFolderViewState: ViewStateConfiguration?.GlobalFolderViewState,
            MailTriageOnHoverActions: ViewStateConfiguration?.MailTriageOnHoverActions?.join(','),
        },
        AttachmentPolicy: {
            ConditionalAccessDirectFileAccessOnPrivateComputersBlocked:
                AttachmentPolicy?.ConditionalAccessDirectFileAccessOnPrivateComputersBlocked,
            ConditionalAccessDirectFileAccessOnPublicComputersBlocked:
                AttachmentPolicy?.ConditionalAccessDirectFileAccessOnPublicComputersBlocked,
            ConditionalAccessWacViewingOnPrivateComputersBlocked:
                AttachmentPolicy?.ConditionalAccessWacViewingOnPrivateComputersBlocked,
            ConditionalAccessWacViewingOnPublicComputersBlocked:
                AttachmentPolicy?.ConditionalAccessWacViewingOnPublicComputersBlocked,
        },
    };
}
