import type { AnalyticsAccountScopeUserSettings } from 'owa-analytics-types';
import { getAccountScopeUserSettings as getAccountScopeUserSettingsForAnalytics } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { cherryPickSessionSettingsProperties } from '../utils/cherryPickSessionSettingsProperties';
import { getGlobalSettingsMailboxInfo } from './getGlobalSettingsMailboxInfo';
import { logDatapoint } from '../loggingFunctions/logDatapoint';
import UsageCoreDatapoint from '../datapoints/UsageCoreDatapoint';

let accountScopeSessionSettings: AnalyticsAccountScopeUserSettings | undefined;

export function getAccountScopeUserSettings() {
    if (!accountScopeSessionSettings) {
        const globalSettingsMailboxInfo = getGlobalSettingsMailboxInfo();
        const accountScopeSessionSettingsInternal =
            globalSettingsMailboxInfo &&
            getAccountScopeUserSettingsForAnalytics(globalSettingsMailboxInfo);

        if (!accountScopeSessionSettingsInternal) {
            logDatapoint(
                new UsageCoreDatapoint('MissingAnalyticsAccountScopeSettings', {
                    gmbi: typeof globalSettingsMailboxInfo,
                    accSett: typeof accountScopeSessionSettings,
                })
            );
        }

        accountScopeSessionSettings = cherryPickSessionSettingsProperties(
            accountScopeSessionSettingsInternal
        );
    }

    return accountScopeSessionSettings;
}
