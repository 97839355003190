import type { CustomDataMap } from 'owa-analytics-types';

export function getUnifiedPerfCustomWaterfall(datapointCustomDataMap: CustomDataMap) {
    // add WF_Custom to unified property
    const checkpointColumns = Object.keys(datapointCustomDataMap).filter(key =>
        key.startsWith('WF_Custom')
    );
    const perfCustomWaterfall = {} as CustomDataMap;
    for (const checkpointColumn of checkpointColumns) {
        if (!perfCustomWaterfall[checkpointColumn]) {
            perfCustomWaterfall[checkpointColumn] = datapointCustomDataMap[checkpointColumn];
        }
    }

    return perfCustomWaterfall;
}
