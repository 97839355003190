/**
 * Use this list to disable problematic events. Events can also be disabled
 * through ECS but they will still be sent during OOBE or before we load application settings.
 */
const diabledEvents = new Set<string>();
diabledEvents.add('RelatedContentFilesAndEmailsRenderingLatency'); // WI: 214595. Reason: Caused spikes every 15 minutes in Aria client_cosmos table.
diabledEvents.add('GetTokenApi'); // WI: 214597. Reason: Caused spikes every 15 minutes in Aria client_event table.

/**
 * Use this list to allow events that are core events.
 * NOT CURRENTLY USED BUT WILL SOON.
 */
const allowedIsCoreEvents = new Set<string>();

export function isEventDisabled(eventName: string): boolean {
    return diabledEvents.has(eventName);
}

export function isCoreEventAllowed(eventName: string): boolean {
    return allowedIsCoreEvents.has(eventName);
}
