import type { OpxSessionInfo, StartupAnalyticsInfo } from 'owa-config';
import type {
    AccountInfo,
    AnalyticsAccountScopeUserSettings,
    AnalyticsSessionInfo,
    AnalyticsUserConfiguration,
    ApplicationSettingsReport,
    ImportData,
} from 'owa-analytics-types';
import type { ApplicationSettings } from 'owa-application-settings';
import type NetworkInfo from '../types/NetworkInfo';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-feature-flags' import is restricted from being used. Feature flags have to be passed in the analytics worker initialization [analytics-worker-lint] */
import { checkUserTypeFlight, checkUberFlight } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getSessionAccountInfo } from 'owa-account-source-list-store/lib/utils/getSessionAccountInfo';
import { getImportHistory } from 'owa-account-import-history/lib/getImportHistory';
import { getWebServerClique, getWebServerForest, getBootFlights } from 'owa-metatags';
import { getCollectorUrl } from 'owa-analytics-utils';
import { isEMEARegion } from 'owa-config';
import { getHighEntropyValues } from 'owa-user-agent';
import { getApplicationSettingsReport, checkApplicationSettings } from 'owa-application-settings';
import { getInitializedAnalytics } from '../initializeAnalytics';
import getNetworkInfo from '../utils/getNetworkInfo';
import { getUserConfiguration } from '../settings/getUserConfiguration';
import { getAccountScopeUserSettings } from '../settings/getAccountScopeUserSettings';
import { getSessionInfo } from '../settings/getSessionInfo';
import { getOpxSessionInfo } from '../settings/getOpxSessionInfo';

export interface AnalyticsSessionProperties {
    userConfiguration: AnalyticsUserConfiguration;
    accountScopeUserSettings: AnalyticsAccountScopeUserSettings;
    sessionInfo: AnalyticsSessionInfo;
    report: ApplicationSettingsReport<ApplicationSettings> | undefined;
    uberCheck: 0 | 1;
    userTypeCheck: 0 | 1;
    appSettingsReliabilityCheck: 0 | 1;
    opxSessionInfo: OpxSessionInfo | undefined;
    sessionAccountInfo: AccountInfo;
    sessionImportData: ImportData | undefined;
    webServerForest: string | undefined;
    clique: string | undefined;
    isEUDBCollectorEndpoint: boolean | undefined;
    isEMEARegion: boolean | undefined;
    networkIno: NetworkInfo | null;
    bootFlights: string | undefined;
    isAnalyticsInitialized: boolean;
    hardware: Record<string, number | string>;
}

export async function getSessionProperties(
    startupAnalyticsInfo: StartupAnalyticsInfo
): Promise<AnalyticsSessionProperties> {
    const opxSessionInfo = await getOpxSessionInfo();

    const collectorUrl = getCollectorUrl(startupAnalyticsInfo.forest);
    const userConfiguration = getUserConfiguration();

    return {
        userConfiguration,
        accountScopeUserSettings: getAccountScopeUserSettings(),
        // isEdu is now part of the userConfiguration we get in startupdata.
        // We keep the fallback logic while the server change is being deployed.
        sessionInfo: await getSessionInfo(startupAnalyticsInfo),
        report: getApplicationSettingsReport(),
        uberCheck: checkUberFlight(),
        userTypeCheck: checkUserTypeFlight(isConsumer()),
        appSettingsReliabilityCheck: checkApplicationSettings(),
        opxSessionInfo,
        sessionAccountInfo: getAccountInfo(),
        sessionImportData: getImportHistoryForAnalytics(),
        webServerForest: getWebServerForest(),
        clique: getWebServerClique(),
        isEUDBCollectorEndpoint: collectorUrl
            ? collectorUrl.indexOf('eu-office.events.data') >= 0
            : false,
        isEMEARegion: isEMEARegion(startupAnalyticsInfo.forest),
        networkIno: getNetworkInfo(),
        bootFlights: getBootFlights()?.sort().join(','),
        isAnalyticsInitialized: !!getInitializedAnalytics(),
        hardware: await getHarwareInfo(),
    };
}

/**
 * Get the import history for analytics
 * @returns Import history
 */
function getImportHistoryForAnalytics(): ImportData | undefined {
    const history = getImportHistory();
    const hasData = history.lastImportDate && history.lastImportApp && history.correlationId;
    return hasData
        ? {
              lastImportDate: history.lastImportDate,
              lastImportApp: history.lastImportApp,
              lastImportCorrelationId: history.correlationId,
          }
        : undefined;
}

/**
 * Returns informaiton about the accounts configured in Outlook
 */
function getAccountInfo(): AccountInfo {
    return getSessionAccountInfo();
}

async function getHarwareInfo() {
    const highEntropyValues = await getHighEntropyValues();
    const hardware: Record<string, number | string> = {
        ...(highEntropyValues || {}),
        deviceMemory: navigator.deviceMemory,
        cores: navigator.hardwareConcurrency,
    };

    if (typeof navigator.storage?.estimate == 'function') {
        const storageEstimate = await navigator.storage.estimate().catch(() => undefined);
        if (storageEstimate) {
            if (storageEstimate.quota) {
                hardware.storageQuota = storageEstimate.quota;
            }
            if (storageEstimate.usage) {
                hardware.storageUsage = storageEstimate.usage;
            }
            if (storageEstimate.usageDetails) {
                hardware.details = JSON.stringify(storageEstimate.usageDetails);
            }
        }
    }

    return hardware;
}
