import type { OpxSessionInfo } from 'owa-config';
import { getOpxHostData } from 'owa-config/lib/getOpxHostData';

let opxSessionInfo: OpxSessionInfo | undefined;

export async function getOpxSessionInfo() {
    if (!opxSessionInfo) {
        try {
            const lazyOpxSessionInfo = getOpxHostData();
            if (lazyOpxSessionInfo) {
                opxSessionInfo = await lazyOpxSessionInfo;
            }
        } catch {
            // no op
        }
    }

    return opxSessionInfo;
}
