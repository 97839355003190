import type { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-js';
import { AriaCoreDatapoint } from '../datapoints/AriaCoreDatapoint';
import { logDatapoint } from '../loggingFunctions/logDatapoint';

const MAX_EVENT_COUNT_PER_MINUTE = 500;
const ONE_MINUTE_IN_MS = 60 * 1000;

let lastCheckAt = Date.now();
let events: Record<string, number> = {};

export function trackEventFrequency(item: IExtendedTelemetryItem) {
    if (item.data?.EventName) {
        const eventName = item.data.EventName as string;

        events[eventName] = events[eventName] ? events[eventName] + 1 : 1;

        const now = Date.now();
        if (now - lastCheckAt > ONE_MINUTE_IN_MS) {
            lastCheckAt = now;
            for (const key of Object.keys(events)) {
                if (events[key] > MAX_EVENT_COUNT_PER_MINUTE) {
                    logEventOverFrequency(eventName, events[key]);
                }
            }
            events = {};
        }
    }
}

function logEventOverFrequency(name: string, count: number) {
    try {
        const errorEventName = 'EventReachedMaxFrequency';
        // Avoid a possible ciruclar dependency
        if (name !== errorEventName) {
            logDatapoint(
                new AriaCoreDatapoint(errorEventName, {
                    eventName: name,
                    count,
                })
            );
        }
    } catch (e) {
        // no-op - we don't want to fail the analytics if this can't be logged
    }
}
