import { createEvent, logOneDSDatapoint } from '../OneDsWrapper';
import type {
    AriaDatapointType,
    DataPointEventType,
    CoreAnalyticsOptions,
} from 'owa-analytics-types';
import type { IEventProperty } from '@microsoft/1ds-analytics-js';
import { EventLatency, ValueKind } from '@microsoft/1ds-analytics-js';
import optionallyTraceDatapoint from '../utils/optionallyTraceDatapoint';
import isDevOrAutomationEnvironment from '../utils/isDevOrAutomationEnvironment';
import { flattenData } from '../utils/flattenData';
import { matchEventDefinition } from '../unifiedTelemetry/eventsMapping';
import { addCommonUnifiedPropreties } from '../unifiedTelemetry/addCommonUnifiedPropreties';
import { ARIA_TENANT_ID, UNIFIED_TABLE_NAME } from 'owa-unified-telemetry';

import { AriaCoreDatapoint } from '../datapoints/AriaCoreDatapoint';
import { mergeEvents } from '../unifiedTelemetry/mergeEvents';
import { getUnifiedPerfCustomWaterfall } from '../unifiedTelemetry/getUnifiedPerfCustomWaterfall';
import { getUnifiedPerfProperties } from '../unifiedTelemetry/getUnifiedPerfProperties';

export async function logUnifiedEvents(
    datapoint: AriaDatapointType,
    analyticsOptions: CoreAnalyticsOptions,
    eventType: string
) {
    const bypassEventMatchingCheck =
        datapoint.getOptions()?.unifiedTelemetry?.bypassEventMatchingCheck;
    const unifiedEvent = bypassEventMatchingCheck
        ? datapoint.getEventName()
        : matchEventDefinition(datapoint.getEventName());
    if (!unifiedEvent || !isTargetEventType(eventType) || !datapoint.unifiedTelemetry) {
        return;
    }

    (datapoint as any).eventName = unifiedEvent; // The name update is required here but shouldn't be allowed anywhere else.
    analyticsOptions.shouldIncludeUserInfoId = true;
    const commonProperties = await addCommonUnifiedPropreties(analyticsOptions);
    const datapointProperties = datapoint.getProperties();
    const properties = mergeEvents(
        datapoint.unifiedTelemetry,
        commonProperties,
        () => getUnifiedPerfCustomWaterfall(datapointProperties),
        () => getUnifiedPerfProperties(datapointProperties)
    );

    if (commonProperties && properties) {
        const unifiedDatapoint = new AriaCoreDatapoint(
            datapoint.getEventName(),
            undefined /* extraCustomData */,
            undefined /* options */,
            flattenData(properties)
        );

        optionallyTraceDatapoint(unifiedDatapoint);

        // Do not log datapoints if we are gulping or in a branch or a tds box.
        if (!isDevOrAutomationEnvironment()) {
            // If TableGrouping exists in UT definition, we use it as the table name, else we fallback to UNIFIED_TABLE_NAME.
            const tableName = (properties.TableGrouping ??
                UNIFIED_TABLE_NAME) as DataPointEventType;
            const e = createEvent(
                tableName,
                unifiedDatapoint.getAllProperties(),
                datapoint.getCreationTime()
            );
            e.latency = EventLatency.Normal; // how quickly event is submitted to Aria
            if (e.data?.['HashedEmail']) {
                e.data['HashedEmail'] = <IEventProperty>{
                    value: e.data['HashedEmail'],
                    kind: ValueKind.Pii_Identity,
                };
            }

            logOneDSDatapoint({
                tenantToken: ARIA_TENANT_ID,
                item: e,
                analyticsOptions,
                useUnifiedSchema: true,
            });
        }
    }
}

function isTargetEventType(eventType: string): boolean {
    return eventType === 'client_event' || eventType === 'client_cosmos';
}
