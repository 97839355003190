export default function getAbsoluteResourceDomain(url: string): string {
    if (url != null) {
        const nameParts = url.split('/');

        if (nameParts.length > 2) {
            return nameParts[2];
        }
    }

    return '';
}
