import type { AnalyticsAccountScopeUserSettings } from 'owa-analytics-types';
import type { ReadOnlyAccountConfiguration } from 'owa-session-store';

export function cherryPickSessionSettingsProperties(
    accountScopeSessionSettings?: ReadOnlyAccountConfiguration
): AnalyticsAccountScopeUserSettings {
    if (!accountScopeSessionSettings) {
        return {};
    }

    const {
        SessionSettings,
        UserOptions,
        ViewStateConfiguration,
        MasterCategoryList,
        AdMarket,
        IsConsumerChild,
    } = accountScopeSessionSettings;

    return {
        SessionSettings: {
            WebSessionType: SessionSettings?.WebSessionType?.toString(),
            IsPremiumConsumerMailbox: SessionSettings?.IsPremiumConsumerMailbox,
            // eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki) Mapping analytics properties
            IsShadowMailbox: SessionSettings?.IsShadowMailbox,
            MailboxGuid: SessionSettings?.MailboxGuid,
            UserEmailAddress: SessionSettings?.UserEmailAddress,
        },
        UserOptions: {
            IsFocusedInboxEnabled: UserOptions?.IsFocusedInboxEnabled,
        },
        ViewStateConfiguration: {
            ListViewBitFlags: ViewStateConfiguration?.ListViewBitFlags,
        },
        MasterCategoryList: {
            MasterList: MasterCategoryList?.MasterList?.length,
        },
        AdMarket,
        IsConsumerChild,
    };
}
