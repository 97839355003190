import type MailboxInfo from 'owa-client-types/lib/MailboxInfo';
import { getGlobalSettingsAccount } from 'owa-account-source-list-store';

let globalSettingsMailboxInfo: MailboxInfo | undefined;

export function getGlobalSettingsMailboxInfo() {
    if (!globalSettingsMailboxInfo) {
        globalSettingsMailboxInfo = getGlobalSettingsAccount().mailboxInfo;
    }

    return globalSettingsMailboxInfo;
}
