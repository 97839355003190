import type { AriaDatapointType, InternalDatapointOptions } from 'owa-analytics-types';
import { AriaCoreDatapoint } from '../datapoints/AriaCoreDatapoint';
import { logDatapoint } from '../loggingFunctions/logDatapoint';

const THROTTLE_POWER = 2;
const ONE_MINUTE = 60 * 1000;

let lastWindowResetTime = Date.now();
let datapointFactor = new Map<string, number>();

export function highCountEventThrottle(
    datapoint: AriaDatapointType,
    rollingWindow?: number,
    throttleAbove?: number
    // properties: CustomDataMap  -- disabled while gathering data
): boolean {
    // Do not throttle core datapoints
    if (
        (datapoint.getOptions() as InternalDatapointOptions)?.isCore ||
        !rollingWindow ||
        !throttleAbove
    ) {
        return true;
    }

    // Reset the datapoint counts if the rolling window has passed
    if (Date.now() - lastWindowResetTime > rollingWindow * ONE_MINUTE) {
        resetDatapointCounts();
    }

    const datapointName = datapoint.getEventName();
    let isThrottled = false;
    let shouldLogOccurence = true;

    const datapointCount = datapoint.sessionOccurrence ?? 1;

    if (datapointCount > throttleAbove) {
        const diff = datapointCount - throttleAbove;
        const factor = datapointFactor.get(datapointName) ?? 1;

        isThrottled = true;

        if (diff === THROTTLE_POWER ** factor) {
            datapointFactor.set(datapointName, factor + 1);
            // Add the total session occurrence count for this datapoint
            // properties.TotalSessionOccurrence = throttleCount; -- disabled while gathering data

            /**
             * We are gattering data while deciding what are the right values for the
             * thresholad and rolling window
             */
            logDatapoint(
                new AriaCoreDatapoint('EventToBeThrottled', {
                    eventName: datapointName,
                    throttleCount: datapointCount,
                    factor,
                    isThrottled,
                    rollingWindow,
                    throttleAbove,
                })
            );
        } else {
            shouldLogOccurence = false;
        }
    }

    // Mark the datapoint as being throttled
    // properties.IsThrottled = isThrottled; -- disabled while gathering data

    return shouldLogOccurence;
}

export function resetDatapointCounts() {
    lastWindowResetTime = Date.now();
    datapointFactor = new Map<string, number>();
}
