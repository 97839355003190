import { getIsAnalyticsWorkerFaulted } from 'owa-config/lib/isAnalyticsWorkerFaulted';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';

let useAnalyticsWorker = false;

export function setIsAnalyticsWorkerActive(useWorker: boolean) {
    useAnalyticsWorker = useWorker;
}

export function isAnalyticsWorkerActive(): boolean {
    const workerIsSupported = typeof Worker !== 'undefined';
    const hasFaulted = getIsAnalyticsWorkerFaulted();
    /**
     * There is a bug with OPX scenarios, for now we will restrict the Analytics Worker
     * to web and native.
     */
    const enabledOnHost = isHostAppFeatureEnabled('analyticsWorker');

    return useAnalyticsWorker && workerIsSupported && !hasFaulted && enabledOnHost;
}
