import { logOneDSDatapoint } from '../OneDsWrapper';
import type { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-js';
import type { CoreAnalyticsOptions } from 'owa-analytics-types';

export async function logAddinsTelemetryEvent(
    tenantToken: string,
    item: IExtendedTelemetryItem,
    analyticsOptions?: CoreAnalyticsOptions
) {
    // Returning is important so the errors can be handled by the main thread.
    return logOneDSDatapoint({
        tenantToken,
        item,
        analyticsOptions,
        skipCommonProperties: true,
    });
}
