import type { AriaDatapointType, PerformanceDatapointType } from 'owa-analytics-types';
import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';
import { safeStringify, validatePropertyBag } from './validatePropertyBag';

const datapointQueryStringParam = 'dp';

// reserved for use by TraceToggle diagnostic component
// managed as module-scope state variable for dep graph reasons and to avoid pulling in Satchel
let shouldTraceDatapoints: boolean = false;
export function getShouldTraceDatapoints(): boolean {
    return shouldTraceDatapoints;
}
export function toggleTraceDatapoints(): void {
    shouldTraceDatapoints = !shouldTraceDatapoints;
}

export default function optionallyTraceDatapoint(datapoint: AriaDatapointType) {
    var eventName = datapoint.getEventName();
    const datapointRegex = RegExp(getQueryStringParameter(datapointQueryStringParam) ?? '.*', 'i');

    if (
        (eventName &&
            hasQueryStringParameter(datapointQueryStringParam) &&
            eventName.match(datapointRegex)) ||
        shouldTraceDatapoints
    ) {
        // This is a debugging tool that developers use to test if there datapoints are getting logged
        /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
         * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
         *	> Unexpected console statement. */
        console.group('Datapoint:' + eventName);
        /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
         * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
         *	> Unexpected console statement. */
        console.log('Properties:' + safeStringify(datapoint.getAllProperties()));
        /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
         * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
         *	> Unexpected console statement. */
        console.log(
            'CustomData:' +
                validatePropertyBag(
                    datapoint.getPropertyBag(),
                    eventName,
                    datapoint.getOptions(),
                    false
                )
        );

        const waterfall = (<PerformanceDatapointType>datapoint).waterfallTimings;
        const unifiedTelemetry = (<PerformanceDatapointType>datapoint).unifiedTelemetry;

        if (waterfall) {
            /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
             * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
             *	> Unexpected console statement. */
            console.log('Waterfall:' + safeStringify(waterfall));
        }

        if (unifiedTelemetry) {
            /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
             * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
             *	> Unexpected console statement. */
            console.log('Unified Telemetry:' + safeStringify(datapoint.unifiedTelemetry));
        }

        /* eslint-disable-next-line no-console  -- (https://aka.ms/OWALintWiki)
         * Baseline legacy exception from before rule was added. Do not copy/paste this justification.
         *	> Unexpected console statement. */
        console.groupEnd();
    }
}
