import type { CustomDataMap } from 'owa-analytics-types';

export function flattenData(data: any, output: any = {}, check: any = new Set()): CustomDataMap {
    for (const key of Object.keys(data)) {
        if (typeof data[key] == 'object' && !check.has(key)) {
            check.add(key);
            flattenData(data[key], output, check);
        } else {
            check.add(key);
            output[key] = data[key];
        }
    }
    return output;
}
