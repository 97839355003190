let msaDeviceTicket: string | null = null;
// Limit logging to once per thread per session
let canLogSettingDeviceIdOnThread = true;
let canLogWrongTicketIdOnThread = true;

export function getMsaDeviceTicket() {
    return msaDeviceTicket;
}

export function setMsaDeviceTicket(ticket: string, logUsage: any /* we can't import the types */) {
    if (!msaDeviceTicket && canLogSettingDeviceIdOnThread) {
        canLogSettingDeviceIdOnThread = false;
        logUsage('TrackDeviceId', { action: 'SettingDeviceIdOnThread', deviceId: ticket });
    } else if (!ticket && canLogWrongTicketIdOnThread) {
        canLogWrongTicketIdOnThread = false;
        logUsage('TrackDeviceId', { action: 'WrongTicketIdOnThread', deviceId: ticket });
    }

    if (ticket && ticket !== msaDeviceTicket) {
        msaDeviceTicket = ticket;
    }
}
