import * as createFunctions from 'owa-unified-telemetry';
import type { CustomDataMap, UnifiedEvent } from 'owa-analytics-types';
import type { UnifiedCommonProperties } from '../types/UnifiedCommonProperties';
import pickBy from 'lodash/pickBy';
import { scrubForPii } from 'owa-config';

// Store all the functions in an object
const unifiedEventsFunctions = pickBy<any>(
    createFunctions as {
        [key: string]: any;
    },
    (value: unknown) => typeof value === 'function'
);

const perfCustomWaterfallFunctionName = 'createUTPerfCustomWaterfall';
const perfPropertiesFunctionName = 'createUTPerfProperties';

export function mergeEvents(
    event: UnifiedEvent,
    commonUnifiedProperties: UnifiedCommonProperties,
    getUnifiedPerfCustomWaterfall: () => CustomDataMap,
    getUnifiedPerfProperties: () => CustomDataMap
): CustomDataMap | null {
    // Create the function name to call
    const functionName = `createUTE${event.eventName}`;

    if (unifiedEventsFunctions[functionName]) {
        const data: CustomDataMap | undefined =
            // We already check the type when passing in the data. This is
            // a general implementation for all the functions.
            (
                event as unknown as {
                    data: CustomDataMap | undefined;
                }
            ).data;
        // Scrub the contextual data for PII
        if (data) {
            for (const key of Object?.keys(data)) {
                if (data[key] && typeof data[key] === 'string' && key !== 'ActionDomain') {
                    data[key] = scrubForPii(data[key] as string);
                }
            }
        }
        // Call the unified telemetry function to generate the final event
        const unifiedEvent = unifiedEventsFunctions[functionName](
            data,
            commonUnifiedProperties.CommonHVAProperties
        );

        // add perfProperties and perfCustomWaterfall if it's a performance event
        // TODO: TableGrouping shows up as empty because createUTE function doesn't set the predefined table grouping. checking with Gerard
        if ('PerfProperties' in unifiedEvent) {
            unifiedEvent.PerfProperties = unifiedEventsFunctions[perfPropertiesFunctionName](
                getUnifiedPerfProperties()
            );
            unifiedEvent.TableGrouping = unifiedEvent.PerfProperties.TableGrouping;
        }
        if ('PerfCustomWaterfall' in unifiedEvent) {
            unifiedEvent.PerfCustomWaterfall = unifiedEventsFunctions[
                perfCustomWaterfallFunctionName
            ](getUnifiedPerfCustomWaterfall());
        }

        return unifiedEvent;
    }

    return null;
}
