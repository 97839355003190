import type { CustomDataMap } from 'owa-analytics-types';

export function getUnifiedPerfProperties(datapointCustomDataMap: CustomDataMap) {
    const perfProperties = {} as CustomDataMap;

    perfProperties.E2ETimeElapsed = datapointCustomDataMap.E2ETimeElapsed;
    perfProperties.Status = datapointCustomDataMap.Status;
    perfProperties.UserPerceivedTime = datapointCustomDataMap.UserPerceivedTime;

    return perfProperties;
}
