import {
    addCommonDiagnosticProperties,
    type DiagnosticOverrides,
} from './utils/addCommonDiagnosticProperties';
import { ApplicationInsights, NRT_PROFILE } from '@microsoft/1ds-analytics-js';
import { type eOfflineValue, type IOfflineListener } from '@microsoft/applicationinsights-common';
import {
    type eStorageProviders,
    type IOfflineChannelConfiguration,
    OfflineChannel,
} from '@microsoft/applicationinsights-offlinechannel-js';
import { PrivacyGuardPlugin } from '@microsoft/1ds-privacy-guard-js';
import pako from 'pako';
import { getClientVersion, getApp } from 'owa-config/lib/bootstrapOptions';
import { getSessionId } from 'owa-config/lib/getSessionId';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import { getMsaDeviceTicket } from 'owa-config/lib/msaDeviceTicket';
import { trace } from 'owa-trace';
import { convertTo1DSTypes } from './utils/convertTo1DSTypes';
import addCommonProperties from './utils/addCommonProperties';
import type { CustomDataMap, DataPointEventType, CoreAnalyticsOptions } from 'owa-analytics-types';
import type {
    IExtendedConfiguration,
    IExtendedTelemetryItem,
    IPayloadData,
    IPlugin,
} from '@microsoft/1ds-analytics-js';
import type { DataContexts } from '@microsoft/1ds-privacy-guard-js';
import { trackEventFrequency } from './utils/trackEventFrequency';
import { getCollectorUrl } from 'owa-analytics-utils';
import { getAnalyticsFlightsAndAppSettings } from './settings/getAnalyticsFlightsAndAppSettings';
import { getOpxSessionInfo } from './settings/getOpxSessionInfo';
import { getUserConfiguration } from './settings/getUserConfiguration';
import { getThreadName } from 'owa-thread-config';
import { getAnalyticsAddon } from 'owa-analytics-addons';
import { getOfflineTelemetryDatabasePrefix } from './utils/getOfflineTelemetryDatabasePrefix';
import { subscribeToNetworkChange } from 'owa-offline/lib/subscribeToNetworkChange';

let lastUpdateTime = -1;
let areCommonPropertiesInitialized = false;

const insights: {
    [tenant: string]: ApplicationInsights;
} = {};
const cacheProperties: {
    [tenant: string]: {
        skipCommonProperties: boolean;
        useUnifiedSchema: boolean;
        endpointUrl?: string;
        msaDeviceTicket: string | null;
        offlineListener?: IOfflineListener;
        unsubscribe?: () => void;
    };
} = {};

async function initializeAppInsights(
    endpointUrl: string | undefined,
    tenantToken: string,
    analyticsOptions?: CoreAnalyticsOptions,
    skipCommonProperties = false,
    useUnifiedSchema = false
) {
    trace.info(
        ` ${
            insights[tenantToken] ? 'Updating existing' : 'Initializing new'
        } 1DS tenant. Tenant: ${tenantToken}`,
        'analytics'
    );

    if (insights[tenantToken]) {
        cacheProperties[tenantToken].offlineListener?.unload();
        cacheProperties[tenantToken].unsubscribe?.();

        // Use timeout to allow internal oneds timers to complete
        const oldInsights = insights[tenantToken];
        setTimeout(() => oldInsights.unload(), 30000);
    }

    insights[tenantToken] = new ApplicationInsights();
    const config: IExtendedConfiguration = {
        instrumentationKey: tenantToken,
        endpointUrl,
        disableCookiesUsage: true, // This stops sending installId in the SDK section
        channelConfiguration: {
            payloadPreprocessor: gzipFunc,
        },
        enableWParam: true, // Set the "w" parameters so the 1ds collector can parse the web content when it comes from the web worker
    };

    let plugins: IPlugin[] | undefined = undefined;
    if (
        getAnalyticsFlightsAndAppSettings()?.enablePrivacyGuard ||
        analyticsOptions?.enablePrivacyGuard
    ) {
        const privacyGuard: PrivacyGuardPlugin = new PrivacyGuardPlugin();
        const sessionSettings = getUserConfiguration()?.SessionSettings;

        const dataCtx: DataContexts = {
            UserName: sessionSettings?.UserDisplayName,
            UserAlias: sessionSettings?.UserPrincipalName?.split('@')[0],
            IgnoredNameParts: ['Microsoft', 'Dogfood'],
        };

        config.extensionConfig = {
            [privacyGuard.identifier]: {
                context: dataCtx,
                scanForUrls: false,
            },
        };
        plugins = [privacyGuard];
    }

    insights[tenantToken].initialize(config, plugins);
    insights[tenantToken].getPostChannel()?._setTransmitProfile(NRT_PROFILE);

    const msaDeviceTicket = getMsaDeviceTicket();
    if (msaDeviceTicket) {
        // This ticket is only available in Native
        insights[tenantToken].getPostChannel()?.setMsaAuthTicket(msaDeviceTicket);
    }

    let offlineListener: IOfflineListener | undefined;
    let unsubscribe: (() => void) | undefined;
    if (getAnalyticsFlightsAndAppSettings()?.enableOfflineCaching) {
        // OfflineChannel is not initialized properly if added during initialization
        // so we add it here via updateCfg / addPlugin
        const offlineChannel = new OfflineChannel();
        const idbProvider: eStorageProviders.IndexedDb = 3;
        const offlineConfig: IOfflineChannelConfiguration = {
            providers: [idbProvider],
            storageKeyPrefix: getOfflineTelemetryDatabasePrefix(tenantToken),
        };
        insights[tenantToken].updateCfg({
            extensionConfig: { [offlineChannel.identifier]: offlineConfig },
        });
        insights[tenantToken].addPlugin(offlineChannel);

        // The client is responsible for telling the OfflineChannel when it is online vs. offline
        offlineListener = offlineChannel.getOfflineListener();
        const offlineValue: eOfflineValue.Offline = 2;
        const onlineValue: eOfflineValue.Online = 1;
        offlineListener?.setOnlineState(self.navigator.onLine ? onlineValue : offlineValue);
        unsubscribe = subscribeToNetworkChange(onLine => {
            () => offlineListener?.setOnlineState(onLine ? onlineValue : offlineValue);
        });
    }

    await addOrUpdateCommonProperties(
        insights[tenantToken],
        analyticsOptions,
        skipCommonProperties,
        useUnifiedSchema
    );

    cacheProperties[tenantToken] = {
        skipCommonProperties,
        useUnifiedSchema,
        endpointUrl,
        msaDeviceTicket,
        offlineListener,
        unsubscribe,
    };
}

export async function logOneDSDatapoint({
    tenantToken,
    item,
    analyticsOptions,
    overrideEndpointUrl,
    skipCommonProperties = false,
    useUnifiedSchema = false,
}: {
    tenantToken: string;
    item: IExtendedTelemetryItem;
    analyticsOptions?: CoreAnalyticsOptions;
    overrideEndpointUrl?: string;
    skipCommonProperties?: boolean;
    useUnifiedSchema?: boolean;
    updateAppSettings?: boolean;
}) {
    const endpointUrl = getEndpointUrlWithOverrides(analyticsOptions, overrideEndpointUrl);

    const diagnosticOverrides: DiagnosticOverrides = {};

    const tenantCacheExist = !!cacheProperties[tenantToken];
    const skipCommonPropertiesChanged =
        cacheProperties[tenantToken]?.skipCommonProperties !== skipCommonProperties;
    const useUnifiedSchemaChanged =
        cacheProperties[tenantToken]?.useUnifiedSchema !== useUnifiedSchema;
    const endpointUrlChanged = cacheProperties[tenantToken]?.endpointUrl !== endpointUrl;
    const msaDeviceTicketChanged =
        cacheProperties[tenantToken]?.msaDeviceTicket !== getMsaDeviceTicket();

    const didCachePropertiesChange =
        tenantCacheExist &&
        (skipCommonPropertiesChanged ||
            useUnifiedSchemaChanged ||
            endpointUrlChanged ||
            msaDeviceTicketChanged);

    if (!insights[tenantToken] || didCachePropertiesChange) {
        if (didCachePropertiesChange) {
            diagnosticOverrides.SkipCommonPropertiesChanged = skipCommonPropertiesChanged;
            diagnosticOverrides.UseUnifiedSchemaChanged = useUnifiedSchemaChanged;
            diagnosticOverrides.EndpointUrlChanged = endpointUrlChanged;
            diagnosticOverrides.MsaDeviceTicketChanged = msaDeviceTicketChanged;
        }

        await initializeAppInsights(
            endpointUrl,
            tenantToken,
            analyticsOptions,
            skipCommonProperties,
            useUnifiedSchema
        );
    }

    if (
        analyticsOptions?.requestConfigUpdateTime &&
        lastUpdateTime !== analyticsOptions.requestConfigUpdateTime
    ) {
        lastUpdateTime = analyticsOptions.requestConfigUpdateTime;
        trace.info(` Updating Common Properties for tenant: ${tenantToken}`, 'analytics');

        await addOrUpdateCommonProperties(
            insights[tenantToken],
            analyticsOptions,
            skipCommonProperties,
            useUnifiedSchema
        );
    }

    if (useUnifiedSchema && item.data) {
        item.data['UserInfo.Id'] = item.data.UserId?.toString();
        item.data['UserInfo.IdType'] = item.data.UserIdType?.toString();
        delete item.data.UserId;
        delete item.data.UserIdType;

        item.data = convertTo1DSTypes(item.data);
    }

    if (!useUnifiedSchema && item.data) {
        if (!item.data.App) {
            item.data.App = getApp();
            trace.warn(` Event ${item.name} should contain a App field.`);
        }

        const hostTelemetry = scrubForPii((await getOpxSessionInfo())?.hostTelemetry);
        if (hostTelemetry) {
            item.data.HostTelemetry = hostTelemetry;
        }
    }

    if (getThreadName() !== 'MAIN_THREAD') {
        diagnosticOverrides.UpdateCachesFromMainThreadIsRegistered = getAnalyticsAddon(
            'GetAndUpdateAnalyticsCachesInWorker'
        ).isRegistered;
    }

    if (!self.navigator.onLine) {
        diagnosticOverrides.Offline = true;
    }

    // Update diagnostics property with common properties
    addCommonDiagnosticProperties(endpointUrl, item, diagnosticOverrides);

    insights[tenantToken].track(item);

    trackEventFrequency(item);
}

async function addOrUpdateCommonProperties(
    appInsights: ApplicationInsights,
    analyticsOptions?: CoreAnalyticsOptions,
    skipCommonProperties?: boolean,
    useUnifiedSchema?: boolean
) {
    // If we are not on the main thread, request and updates the analytics
    // settings caches before ressetting the common properties (but only if we need to update them)
    if (getThreadName() !== 'MAIN_THREAD' && areCommonPropertiesInitialized) {
        if (getAnalyticsAddon('GetAndUpdateAnalyticsCachesInWorker').isRegistered) {
            await getAnalyticsAddon('GetAndUpdateAnalyticsCachesInWorker')?.executeNow();
            trace.info(' 1DS - Caches updated from main thread', 'analytics');
        } else {
            trace.warn(
                ` 1DS - Callback to update caches missing on ${getThreadName()}`,
                'analytics'
            );
        }
    }

    const propertyManager = appInsights.getPropertyManager();
    if (!skipCommonProperties && !useUnifiedSchema && analyticsOptions) {
        await addCommonProperties(propertyManager, analyticsOptions);
    }

    const context = propertyManager.getPropertiesContext();
    if (context) {
        context.app.ver = getClientVersion();
        context.session.setId(getSessionId());
        context.web.domain = undefined;
        // We do not set the local deviceId because it can cause the network request to fail
        // if the formatting is not correct. We log it as a separate property instead.
        context.device.localId = undefined;
        if (analyticsOptions?.overrideOsVersion) {
            context.os.ver = analyticsOptions.overrideOsVersion;
        }
    }

    areCommonPropertiesInitialized = true;
}

function gzipFunc(payload: IPayloadData, cb: (data: IPayloadData) => void) {
    try {
        const dataToSend = pako.gzip(payload.data);
        const headers = payload.headers || {};
        headers['Content-Encoding'] = 'gzip';
        payload.headers = headers;
        payload.data = dataToSend;
        cb(payload);
    } catch (err) {
        // send original payload on error
        return cb(payload);
    }
}

function getEndpointUrlWithOverrides(
    analyticsOptions?: CoreAnalyticsOptions,
    overrideEndpointUrl?: string
) {
    if (overrideEndpointUrl) {
        return overrideEndpointUrl;
    }

    if (analyticsOptions?.endpointUrlOverride === undefined) {
        // Override is not passed in analyticsOptions, hence use the fallback collector url.
        return getCollectorUrl(analyticsOptions?.startupInfo.forest);
    }

    return analyticsOptions.endpointUrlOverride === null
        ? undefined // Converting null to undefined, to use the default 1DS collector url.
        : analyticsOptions.endpointUrlOverride;
}

export function oneDSFlush() {
    for (const analytics of Object.values(insights) as ApplicationInsights[]) {
        if (analytics && analytics.isInitialized()) {
            try {
                analytics.getPostChannel().flush();
            } catch {}
        }
    }
}

export function addMsaAuthTicket(ticket: string) {
    for (const analytics of Object.values(insights)) {
        if (analytics) {
            try {
                analytics.getPostChannel().setMsaAuthTicket(ticket);
            } catch {}
        }
    }
}

export function createEvent(
    eventName: DataPointEventType,
    props?: CustomDataMap,
    time?: string
): IExtendedTelemetryItem {
    return {
        name: eventName,
        data: props ? JSON.parse(JSON.stringify(props)) : {},
        time,
    };
}
