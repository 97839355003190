import type { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-js';

const piiKeys = ['HashedEmail', 'PiiData'];

export function convertTo1DSTypes(
    properties: IExtendedTelemetryItem['data']
): IExtendedTelemetryItem['data'] {
    if (properties) {
        for (const key of Object.keys(properties)) {
            if (properties[key] === undefined || properties[key] === null) {
                properties[key] = '';
            } else if (typeof properties[key] === 'object' && !piiKeys.includes(key)) {
                properties[key] = JSON.stringify(properties[key]);
            }
        }
    }

    return properties;
}
