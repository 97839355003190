import { hasQueryStringParameter } from 'owa-querystring';
import { isTdsBox } from 'owa-config/lib/isTdsBox';
import { isGulpOrBranching } from 'owa-config/lib/bootstrapOptions';

export default function isDevOrAutomationEnvironment(): boolean {
    if (hasQueryStringParameter('teAnLoc')) {
        return false;
    }
    return isGulpOrBranching() || isTdsBox() || hasQueryStringParameter('testAutomation');
}
