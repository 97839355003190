import type { AnalyticsUserConfiguration } from 'owa-analytics-types';
import { default as getUserConfigurationForAnalytics } from 'owa-session-store/lib/actions/getUserConfiguration';
import { cherryPickUserConfiguration } from '../utils/cherryPickUserConfiguration';

let userConfigurationCache: AnalyticsUserConfiguration;

export function getUserConfiguration() {
    if (!userConfigurationCache) {
        userConfigurationCache = cherryPickUserConfiguration(getUserConfigurationForAnalytics());
    }

    return userConfigurationCache;
}

export function updateUserConfigurationCache(userConfiguration: AnalyticsUserConfiguration) {
    userConfigurationCache = userConfiguration;
}
