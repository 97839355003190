import type { AnalyticsSessionInfo } from 'owa-analytics-types';
import type { StartupAnalyticsInfo } from 'owa-config';
import { getClientId, getLayout, getLogicalRing } from 'owa-config';
import { getTenantGuid } from 'owa-shared-start/lib/sessionDiagnostics';
import { getUserConfiguration } from '../settings/getUserConfiguration';

let sessionInfoCache: AnalyticsSessionInfo | undefined;

export async function getSessionInfo(
    startupInfo: StartupAnalyticsInfo
): Promise<AnalyticsSessionInfo> {
    if (!sessionInfoCache) {
        sessionInfoCache = {
            isEdu: getUserConfiguration()?.SessionSettings?.IsEdu,
            clientId: getClientId(),
            logicalRing: getLogicalRing() as string,
            tenantGuid: getTenantGuid(),
            layout: getLayout(),
            ...startupInfo,
        };
    }

    return sessionInfoCache;
}
